import {Component, HostListener, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {NotificationsService} from "angular2-notifications";
import {RemoteConfigService} from "./remote-config.service";
import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {RemoteConfig} from "./remote-config";
import {isString} from "lodash";
import {PageTypeEnum} from "../../common/page";
import {NgIf} from '@angular/common';
import {InternalNotificationService} from '../../functions/notification';

@Component({
  selector: 'app-remote-config',
  templateUrl: './remote-config.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf
  ],
  styleUrls: ['./remote-config.component.scss']

})
export class RemoteConfigComponent implements OnInit {
  public uploadedFile: File|null = null;

  constructor(
    private internalNotificationService: InternalNotificationService,
    private remoteConfigService: RemoteConfigService,
    private router: Router,
  ) {
    this.remoteConfigForm.controls.content.addValidators(this.requiredFileType('json'));
    this.remoteConfigForm.controls.content.addAsyncValidators(this.isValidJson);
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;

    const match = currentUrl.match(/\/(\d+)\//);
    if (match && match.length > 1) {
      const id = parseInt(match[1], 10);
      this.fetchRemoteConfig(id);
    } else {
      console.log('No remote config id found in the URL');
    }
  }

  private editingRemoteConfig: RemoteConfig|null = null;

  remoteConfigForm = new FormGroup({
    name:            new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(128)]),
    condition:       new FormControl(null, [Validators.maxLength(256)]),
    minVersionMinor: new FormControl(null),
    minVersionMajor: new FormControl(null),
	  minVersionBuild: new FormControl(null),
    content:         new FormControl(null, [Validators.required]),
  });

  onFileUploadBtn($event: any) {
    const file = $event.target?.files[0] as File;
    this.onFileReceive(file);
  }

  onFileReceive(file: File) {
    this.uploadedFile = file;
    this.remoteConfigForm.controls.content.setValue(this.uploadedFile);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const file = (event.dataTransfer?.files || [])[0];
    if (file) {
      this.onFileReceive(file);
    }
  }

  getFileInputColor() {
    if (this.remoteConfigForm.controls.content.value == null) {
      return 'gray';
    }

    if (this.remoteConfigForm.controls.content.invalid) {
      return 'red';
    }

    return 'green';
  }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }

  requiredFileType(type: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const file = control.value as File;

      if (file && file instanceof File) {
        const fileName = file.name.toLowerCase();
        const isValidExtension = fileName.endsWith(`.${type.toLowerCase()}`);

        if (isValidExtension) {
          return null;
        } else {
          this.internalNotificationService.showError('Файл має бути формату .json');
          return { invalidFileType: { value: control.value } };
        }
      }

      return null;
    };
  }

  isValidJson = async (control: AbstractControl): Promise<ValidationErrors | null> => {
    if (control.value instanceof File) {
      const jsonFile = control.value as File;

      const jsonData = await jsonFile.text();
      try {
        JSON.parse(jsonData);
        return null;
      } catch (e) {
        this.internalNotificationService.showError('Файл повинен бути валідним Json');
        return {invalidJson: {value: control.value}};
      }
    } else if (typeof control.value === 'string') {
      try {
        JSON.parse(control.value);
        return Promise.resolve(null);
      } catch (e) {
        this.internalNotificationService.showJsonInvalidError();
        return Promise.resolve({ invalidJson: { value: control.value } });
      }
    } else {
      return Promise.reject({ invalidType: { value: control.value } });
    }
  };

  fetchRemoteConfig(id: number): void {
    this.remoteConfigService.getRemoteConfig(id).subscribe({
      next: (data) => {
        if (data.body !== null) {
          this.editingRemoteConfig = {
            id: data.body.id,
            name: data.body.name,
            username: data.body.username,
            condition: data.body.condition,
            content: data.body.content,
            createdAt: data.body.createdAt,
            groupId: data.body.groupId,
            minVersionBuild: data.body.minVersionBuild,
            minVersionMajor: data.body.minVersionMajor,
            minVersionMinor: data.body.minVersionMinor,
            userId: data.body.userId
          } as RemoteConfig

          this.remoteConfigForm.patchValue({
            name: data.body.name,
            condition: data.body.condition,
            minVersionMajor: data.body.minVersionMajor,
            minVersionMinor: data.body.minVersionMinor,
            minVersionBuild: data.body.minVersionBuild,
            content: data.body.content
          })
        }
      },
      error: (error) => {
        this.router.navigate([PageTypeEnum.REMOTE_CONFIG_CREATE])
        this.internalNotificationService.handleError(error, 'Невдалося отримати конфіг');
      }
    })
  }

  isEditingRemoteConfigNotEqualToFormValues(): boolean
  {
    if (this.editingRemoteConfig === null) {
      return true;
    }

    if (this.remoteConfigForm.value.name === this.editingRemoteConfig.name
      && this.remoteConfigForm.value.condition === this.editingRemoteConfig.condition
      && this.remoteConfigForm.value.minVersionMajor === this.editingRemoteConfig.minVersionMajor
      && this.remoteConfigForm.value.minVersionMinor === this.editingRemoteConfig.minVersionMinor
      && this.remoteConfigForm.value.minVersionBuild === this.editingRemoteConfig.minVersionBuild
      && this.remoteConfigForm.value.content === this.editingRemoteConfig.content
    ) {
      return false
    }

    return true;
  }

  save() {
    if (!confirm('Ви впевнені що хочете зберегти даний конфіг?')) {
      return;
    }

    if (this.remoteConfigForm.valid) {
      this.remoteConfigService.saveRemoteConfig(
        this.remoteConfigForm.value.name,
        this.remoteConfigForm.value.condition,
        this.remoteConfigForm.value.minVersionMinor,
        this.remoteConfigForm.value.minVersionMajor,
        this.remoteConfigForm.value.minVersionBuild,
        this.remoteConfigForm.value.content,
        this.editingRemoteConfig?.id
      ).subscribe({
        next: (data) => {
          if (data.status === 201) {
            this.internalNotificationService.showSuccess('Ремоут конфіг додано')
            if (this.editingRemoteConfig !== null) {
              this.editingRemoteConfig = null;
              this.router.navigate([PageTypeEnum.REMOTE_CONFIG_CREATE])
            } else {
              window.location.reload();
            }
            this.reset();
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, 'Невдалося зберегти конфіг');
        }
      })
    }
  }

  reset() {
    this.uploadedFile = null;

    if (this.editingRemoteConfig) {
      this.remoteConfigForm.reset({
        name: this.editingRemoteConfig.name,
        condition: this.editingRemoteConfig.condition,
        minVersionMajor: this.editingRemoteConfig.minVersionMajor,
        minVersionMinor: this.editingRemoteConfig.minVersionMinor,
        minVersionBuild: this.editingRemoteConfig.minVersionBuild,
        content: this.editingRemoteConfig.content,
      });

      return
    }

    this.remoteConfigForm.reset({
      name: null,
      condition: null,
      minVersionMajor: null,
      minVersionMinor: null,
	    minVersionBuild: null,
      content: null,
    });
  }
}
