import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RenovationListService} from "./renovation-list.service";
import {RenovationStatus} from "../renovation/renovation";
import {MapElementListService} from "../map-element-list/map-element-list.service";
import {ChunkListService} from "../chunk-list/chunk-list.service";
import {SetListService} from "../set-list/set-list.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {SkinListService} from "../skin-list/skin-list.service";
import {appVersionValidator} from "../../../form-validators/validators";
import {InternalNotificationService} from '../../../functions/notification';
import {NgForOf, NgIf} from '@angular/common';
import {SetListComponent} from '../set-list/set-list.component';
import {ChunkListComponent} from '../chunk-list/chunk-list.component';
import {SkinListComponent} from '../skin-list/skin-list.component';
import {MapElementListComponent} from '../map-element-list/map-element-list.component';

@Component({
  selector: 'app-renovation-list',
  templateUrl: './renovation-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    SetListComponent,
    ChunkListComponent,
    SkinListComponent,
    MapElementListComponent,
    ReactiveFormsModule,
    NgForOf
  ],
  styleUrls: ['./renovation-list.component.scss']
})
export class RenovationListComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  isActivePendingOption: boolean = true;
  isActiveSavedOption: boolean = true;
  isActiveConfirmedOption: boolean = true;

  constructor(
    private internalNotificationService: InternalNotificationService,
    public renovationListService: RenovationListService,
    public mapElementListService: MapElementListService,
    public chunkListService: ChunkListService,
    public skinListService: SkinListService,
    public setListService: SetListService,
  ) {}

  ngOnInit(): void {
    this.renovationListService.fetchAndLoadSkinRenovations()
  }

  saveVersionedSkinRenovationForm = new FormGroup({
    appVersion: new FormControl<string|null>(null, [Validators.required, appVersionValidator]),
  });

  confirmVersionedSkinRenovationForm  = new FormGroup({
    gitBranch: new FormControl<string|null>(null, [Validators.required, Validators.maxLength(256)]),
  });

  saveSkinRenovation() {
    if (this.saveVersionedSkinRenovationForm.valid && this.renovationListService.isCreatedAtLeastOneElement()) {
      const appVersion = this.saveVersionedSkinRenovationForm.controls.appVersion.value;

      if (!appVersion || !confirm("Ви впевнені що хочете зберегти усі створені сутності під версією: " + appVersion + '?')) {
        return;
      }

      const saveVersionedSkinRenovation = this.renovationListService.saveVersionedRenovationList(appVersion);

      if (saveVersionedSkinRenovation) {
        this.renovationListService.createSkinRenovationStatus(saveVersionedSkinRenovation).subscribe({
            next: (data) => {
              if (data.status === 201) {
                this.internalNotificationService.showSuccess('Збережено')
                this.renovationListService.fetchAndLoadSkinRenovations();
              }
            },
            error: (error) => {
              this.internalNotificationService.handleError(error, 'Невдалося підтвердити версіоновану реновацію скінів');
            }
        })
      }
    }
  }

  confirmSkinRenovation(id: number) {
    if (this.confirmVersionedSkinRenovationForm.valid) {
      const gitBranch = this.confirmVersionedSkinRenovationForm.controls.gitBranch.value

      this.renovationListService.confirmSkinRenovation(id, <string>gitBranch).subscribe({
        next: (data) => {
          if (data.status === 200) {
            this.internalNotificationService.showSuccess('Підтверджено')
            this.renovationListService.fetchAndLoadSkinRenovations();
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, 'Невдалося підтвердити версіоновану реновацію скінів');
        }
      });
    }
  }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }
}
