import {Injectable} from '@angular/core';
import {
  RenovationList, RenovationOrderList,
  SkinRenovationChangeStatus
} from "./renovation-list";
import {RenovationStatus} from "../renovation/renovation";
import {ChunkListService} from "../chunk-list/chunk-list.service";
import {SetListService} from "../set-list/set-list.service";
import {SkinListService} from "../skin-list/skin-list.service";
import {MapElementListService} from "../map-element-list/map-element-list.service";
import {ImportSkin, RareLevel, SkinType, SoftCurrency} from "../skin/skin";
import {Observable} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {InternalNotificationService} from '../../../functions/notification';

@Injectable({
  providedIn: 'root'
})
export class RenovationListService {

  savedRenovationLists: RenovationList[] = []
  confirmedRenovationLists: RenovationList[] = []

  renovationOrderMap = new Map<string, RenovationOrderList>();

  hiddenSavedRenovationLists: string[] = []
  hiddenConfirmedRenovationLists: string[] = []

  constructor(
    private internalNotificationService: InternalNotificationService,
    private http: HttpClient,
    private setListService: SetListService,
    private skinListService: SkinListService,
    private mapElementListService: MapElementListService,
    private chunkListService: ChunkListService,
  ) {}

  saveVersionedRenovationList(appVersion: string): SkinRenovationChangeStatus | null {
    if (this.setListService.selectedSets.length === 0
      && this.chunkListService.selectedChunks.length === 0
      && this.mapElementListService.selectedMapElements.length === 0
      && this.skinListService.selectedSkins.length === 0
    ) {
      this.setListService.selectAllSets()
      this.chunkListService.selectAllChunks()
      this.mapElementListService.selectAllMapElements()
      this.skinListService.selectAllSkins()
    } else {
      this.setListService.notSelectedSetsWarning = []
      this.mapElementListService.notSelectedMapElementsWarning = []
      this.chunkListService.notSelectedChunksWarning = []

      for (const skin of this.skinListService.selectedSkins) {
        if (skin.set
          && this.setListService.pendingSets.some(pendingSet => pendingSet.id === skin.set?.id)
          && !this.setListService.selectedSets.some(set => set.id === skin.set?.id))
        {
          if (!this.setListService.notSelectedSetsWarning.includes(skin.set.id)) {
            this.setListService.notSelectedSetsWarning.push(skin.set.id)
          }
        }

        for (const mapElement of skin.mapElements) {
          if (this.mapElementListService.pendingMapElements.some(pendingMapElement => pendingMapElement.id === mapElement.id)
            && !this.mapElementListService.selectedMapElements.some(selectedMapElement => selectedMapElement.id === mapElement.id))
          {
            if (!this.mapElementListService.notSelectedMapElementsWarning.includes(mapElement.id)) {
              this.mapElementListService.notSelectedMapElementsWarning.push(mapElement.id)
            }
          }

          if (mapElement.chunk
            && this.chunkListService.pendingChunks.some(pendingChunk => pendingChunk.id === mapElement.chunk?.id)
            && !this.chunkListService.selectedChunks.some(selectedChunk => selectedChunk.id === mapElement.chunk?.id))
          {
            if (!this.chunkListService.notSelectedChunksWarning.includes(mapElement.chunk.id)) {
              this.chunkListService.notSelectedChunksWarning.push(mapElement.chunk.id)
            }
          }
        }
      }

      for (const mapElement of this.mapElementListService.selectedMapElements) {
        if (mapElement.chunk
          && this.chunkListService.pendingChunks.some(pendingChunk => pendingChunk.id === mapElement.chunk?.id)
          && !this.chunkListService.selectedChunks.some(selectedChunk => selectedChunk.id === mapElement.chunk?.id))
        {
          if (!this.chunkListService.notSelectedChunksWarning.includes(mapElement.chunk.id)) {
            this.chunkListService.notSelectedChunksWarning.push(mapElement.chunk.id)
          }
        }
      }

      console.log(this.setListService.notSelectedSetsWarning, this.mapElementListService.notSelectedMapElementsWarning, this.chunkListService.notSelectedChunksWarning)

      if (this.setListService.notSelectedSetsWarning.length > 0
        || this.mapElementListService.notSelectedMapElementsWarning.length > 0
        || this.chunkListService.notSelectedChunksWarning.length > 0
      ) {
        this.internalNotificationService.showWarn("Неможливо зберегти тільки вибрані сутності", 'Відміна');
        return null
      }
    }

    return {
      id: 0,
      appVersion: appVersion,
      status: RenovationStatus.SAVED,
      gitBranch: '',
      setsIds: this.setListService.selectedSets.map(set=> set.id),
      chunksIds: this.chunkListService.selectedChunks.map(chunk=> chunk.id),
      mapElementsIds: this.mapElementListService.selectedMapElements.map(mapElement => mapElement.id),
      skinsIds: this.skinListService.selectedSkins.map(skin=> skin.id),
      createdAt: new Date(),
      updatedAt: null,
    }
  }

  fetchSkinRenovations(): Observable<HttpResponse<RenovationList[]>> {
    return this.http.post<RenovationList[]>('/api/v1/ui/skin-renovations', {
      "orderList": Array.from(this.renovationOrderMap.values())
    }, {
      observe: 'response',
      withCredentials: true
    });
  }

  getRenovationStatusById(id: number): RenovationStatus {
    for (const savedRenovation of this.savedRenovationLists) {
      if (savedRenovation.id === id) {
        return savedRenovation.status
      }
    }

    for (const confirmedRenovation of this.confirmedRenovationLists) {
      if (confirmedRenovation.id === id) {
        return confirmedRenovation.status
      }
    }

    return RenovationStatus.PENDING
  }

  createSkinRenovationStatus(skinRenovation: SkinRenovationChangeStatus): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/skin-renovations/save', skinRenovation, {
      observe: 'response',
      withCredentials: true
    });
  }

  confirmSkinRenovation(id: number, gitBranch: string) {
    return this.http.patch<any>('/api/v1/ui/skin-renovations/' + id + '/confirm', {
      gitBranch: gitBranch
    }, {
      observe: 'response',
      withCredentials: true
    });
  }

  addHiddenSavedRenovationList(appVersion: string) {
    let index = this.hiddenSavedRenovationLists.indexOf(appVersion);

    if (index === -1) {
      this.hiddenSavedRenovationLists.push(appVersion);
    } else {
      this.hiddenSavedRenovationLists.splice(index, 1);
    }
  }

  addHiddenConfirmedRenovationList(appVersion: string) {
    let index = this.hiddenConfirmedRenovationLists.indexOf(appVersion);

    if (index === -1) {
      this.hiddenConfirmedRenovationLists.push(appVersion);
    } else {
      this.hiddenConfirmedRenovationLists.splice(index, 1);
    }
  }

  public isCreatedAtLeastOneElement(): boolean  {
    return this.setListService.pendingSets.length > 0
      && this.chunkListService.pendingChunks.length > 0
      && this.mapElementListService.pendingMapElements.length > 0
      && this.skinListService.pendingSkins.length > 0
  }

  public fetchAndLoadSkinRenovations(): void {
    this.resetSkinRenovations()

    this.fetchSkinRenovations().subscribe({
      next: (data) => {
        if (data.status === 200 && data.body !== null) {
          this.loadSkinRenovations(Object.values(data.body).reverse())
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, 'Невдалося підтвердити версіоновану реновацію скінів');
      }
    })
  }

  private resetSkinRenovations(): void {
    this.setListService.pendingSets = []
    this.chunkListService.pendingChunks = []
    this.mapElementListService.pendingMapElements = []
    this.skinListService.pendingSkins = []

    this.savedRenovationLists = []
    this.hiddenSavedRenovationLists = []
    this.mapElementListService.savedMapElements = []
    this.chunkListService.savedChunks = []
    this.setListService.savedSets = []

    this.confirmedRenovationLists = []
    this.hiddenConfirmedRenovationLists = []
    this.mapElementListService.confirmedMapElements = []
    this.chunkListService.confirmedChunks = []
    this.setListService.confirmedSets = []
  }

  private loadSkinRenovations(skinRenovations: RenovationList[]): void {
    for (const skinRenovation of skinRenovations) {
      switch (skinRenovation.status) {
        case RenovationStatus.PENDING:
          this.setListService.pendingSets = skinRenovation.sets ?? []
          this.chunkListService.pendingChunks = skinRenovation.chunks ?? []
          this.mapElementListService.pendingMapElements = skinRenovation.mapElements ?? []
          this.skinListService.pendingSkins = skinRenovation.skins ?? []
          break
        case RenovationStatus.SAVED:
          this.savedRenovationLists.push(skinRenovation)
          this.hiddenSavedRenovationLists.push(skinRenovation.appVersion)
          this.mapElementListService.savedMapElements = this.mapElementListService.savedMapElements.concat(skinRenovation.mapElements ?? [])
          this.chunkListService.savedChunks = this.chunkListService.savedChunks.concat(skinRenovation.chunks ?? [])
          this.setListService.savedSets = this.setListService.savedSets.concat(skinRenovation.sets ?? [])
          break
        case RenovationStatus.CONFIRMED:
          this.confirmedRenovationLists.push(skinRenovation)
          this.hiddenConfirmedRenovationLists.push(skinRenovation.appVersion)
          this.mapElementListService.confirmedMapElements = this.mapElementListService.confirmedMapElements.concat(skinRenovation.mapElements ?? [])
          this.chunkListService.confirmedChunks = this.chunkListService.confirmedChunks.concat(skinRenovation.chunks ?? [])
          this.setListService.confirmedSets = this.setListService.confirmedSets.concat(skinRenovation.sets ?? [])
      }
    }
  }
}
