import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Dashboard} from "./dashboard";
import {DashboardService} from "./dashboard.service";
import {HttpClient} from "@angular/common/http";
import {InternalNotificationService} from '../functions/notification';
import {AsyncPipe, NgIf} from '@angular/common';
import {Chart, registerables} from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe
  ],
  providers: [
    HttpClient,
  ],
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  // chart: any;
  dashboard$: Observable<Dashboard>;

  constructor(
    private dashboardService: DashboardService,
    private internalNotificationService: InternalNotificationService,
  ) {
    this.dashboard$ = this.dashboardService.dashboard$;
  }

  ngOnInit(): void {
    this.fetchDashboardData()
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  fetchDashboardData(): void {
    this.dashboardService.getDashboardData().subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.dashboardService.setDashboard(data.body);
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося отримати загальні дані");
      }
    })
  }

  createChart(): void {
    // Chart.register(...registerables);
    // this.chart = new Chart('myChart', {
    //   type: 'line',
    //   data: {
    //     labels: ['January', 'February', 'March', 'April', 'May'],
    //     datasets: [
    //       {
    //         label: 'Sales',
    //         data: [10, 20, 30, 40, 50],
    //         backgroundColor: ['red', 'blue', 'green', 'yellow', 'purple'],
    //         borderColor: 'black',
    //         borderWidth: 1,
    //       },
    //     ],
    //   },
    //   options: {
    //     responsive: true,
    //     plugins: {
    //       title: {
    //         // display: true,
    //         // text: 'Chart.js Line Chart - Logarithmic'
    //       }
    //     },
    //     scales: {
    //       x: {
    //         display: true,
    //       },
    //       y: {
    //         display: true,
    //         // type: 'logarithmic',
    //       }
    //     }
    //   }
    // });
    // console.log("CHART", this.chart);
  }
}
