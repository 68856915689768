import {Component, Input, OnInit} from '@angular/core';
import {Category, MapElement} from "../map-element/map-element";
import {MapElementListService} from "./map-element-list.service";
import {RenovationService} from "../renovation/renovation.service";
import {RenovationOperation, RenovationStatus} from "../renovation/renovation";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {MapElementService} from "../map-element/map-element.service";
import {RenovationOrderList} from "../renovation-list/renovation-list";
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {InternalNotificationService} from '../../../functions/notification';

@Component({
  selector: 'app-map-element-list',
  templateUrl: './map-element-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgForOf
  ],
  styleUrls: ['./map-element-list.component.scss']
})
export class MapElementListComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  @Input() renovationId!: number;
  @Input() listType!: RenovationStatus;
  @Input() renovationOperation!: RenovationOperation;
  @Input() loadedMapElements: MapElement[] = [];

  isActiveMapElementList: boolean = false;

  constructor(
    private internalNotificationService: InternalNotificationService,
    public mapElementService: MapElementService,
    public renovationService: RenovationService,
    public renovationListService: RenovationListService,
    public mapElementListService: MapElementListService,
  ) {}

  ngOnInit(): void {}

  getIsActiveMapElementList() {
    return this.isActiveMapElementList
  }

  setIsActiveMapElementList() {
    this.isActiveMapElementList = !this.isActiveMapElementList
  }

  isRenovationOperationImport(): boolean {
    return this.renovationOperation === RenovationOperation.IMPORT
  }

  changeOrderBy(columnName: string) {
    this.mapElementListService.changeOrderBy(columnName);
    this.renovationListService.renovationOrderMap.set(this.renovationId.toString() + "-mapElements", {
      skinRenovationId: this.renovationId,
      element: 'mapElements',
      orderBy: this.mapElementListService.orderByColumn,
      orderType: this.mapElementListService.orderDirection,
    } as RenovationOrderList)
    this.renovationListService.fetchAndLoadSkinRenovations()
  }

  deleteMapElement(mapElement: MapElement) {
    if (!confirm("Ви впевнені що видалити елемент мапи: " + mapElement.uniqueId + ' та скіни в очікуванні що його використовують?')) {
      return;
    }

    this.mapElementService.deleteMapElement(mapElement.id).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess('Елемент мапи видалено')
          this.renovationListService.fetchAndLoadSkinRenovations()
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося видалити елемент мапи з очікування");
      }
    })
  }

  editMapElement(mapElement: MapElement) {
    this.renovationService.activateMapElementForm();
    this.mapElementService.editableMapElement = mapElement
  }
}
