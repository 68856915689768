import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {RaceCompetitionConfig} from '../race-competition-config/race-competition-config';
import {InternalNotificationService} from '../../functions/notification';
import {RaceCompetitionConfigListService} from './race-competition-config-list-service';
import {RaceCompetitionConfigService} from '../race-competition-config/race-competition-config-service';
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {NgxJsonViewerComponent} from '../../components/ngx-json-viewer/ngx-json-viewer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-race-competition-config-list',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgxJsonViewerComponent,
    ReactiveFormsModule,
    NgTemplateOutlet,
    AsyncPipe,
    FormsModule
  ],
  templateUrl: './race-competition-config-list.component.html',
  styleUrl: './race-competition-config-list.component.scss'
})
export class RaceCompetitionConfigListComponent implements OnInit {

  raceCompetitionConfigs$: Observable<RaceCompetitionConfig[]>;
  isEditedContentList: string[] = [];

  constructor(
    public raceCompetitionConfigService: RaceCompetitionConfigService,
    public raceCompetitionConfigListService: RaceCompetitionConfigListService,
    private internalNotificationService: InternalNotificationService,
  ) {
    this.raceCompetitionConfigs$ = this.raceCompetitionConfigListService.raceCompetitionConfigs$;
  }

  ngOnInit(): void {
    this.fetchRaceCompetitionConfigList();
  }

  fetchRaceCompetitionConfigList() {
    this.raceCompetitionConfigListService.getRaceCompetitionConfigList().subscribe((data) => {
      if (data.body !== null) {
        this.raceCompetitionConfigListService.setRaceCompetitionConfigs(data.body)
        const rangeHeader = data.headers.get('Content-Range');

        if (rangeHeader !== null) {
          this.raceCompetitionConfigListService.parseRangeHeader(rangeHeader)
        }
      } else {
        this.raceCompetitionConfigListService.setRaceCompetitionConfigs([])
      }
    });
  }

  changeOrderBy(columnName: string) {
    this.raceCompetitionConfigListService.changeOrderBy(columnName);
    this.fetchRaceCompetitionConfigList()
  }

  changePage(page: number) {
    this.raceCompetitionConfigListService.changePage(page);
    this.fetchRaceCompetitionConfigList()
  }

  updateRaceCompetitionConfig(config: RaceCompetitionConfig) {
    if (!confirm('Ви впевнені що хочете змінити конфіг змагань?')) {
      return;
    }

    this.raceCompetitionConfigService.updateRaceCompetitionConfig(config.id, config).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess('Конфіг змагань змінено')
          this.fetchRaceCompetitionConfigList();
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, 'Невдалося оновити конфіг змагань');
      }
    })
  }

  deleteRaceCompetitionConfig(config: RaceCompetitionConfig) {
    if (!confirm('Ви впевнені що хочете видалити конфіг змагань #' + config.id + '?')) {
      return;
    }

    this.raceCompetitionConfigService.deleteRaceCompetitionConfig(config.id).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess('Конфіг змагань видалено')
          this.fetchRaceCompetitionConfigList();
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, 'Невдалося видалити конфіг змагань');
      }
    })
  }

  downloadRemoteConfigConfigAsJsonFile(name: string, content: string) {
    const blob = new Blob([content], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.json';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  editContentAndCloseOthers(id: number, content: string) {
    if (typeof this.isEditedContentList[id] === 'string') {
      delete this.isEditedContentList[id];
      return
    }
    this.isEditedContentList[id] = content;
  }

  parseContentStringToJson(id: number) {
    try {
      return JSON.parse(this.isEditedContentList[id]);
    } catch (e) {
      console.warn("Invalid remote config json");
      return {
        "error": "Invalid json"
      };
    }
  }

}
