import {Injectable} from '@angular/core';
import {Category, MapElement, Projection} from "./map-element";
import {v4 as uuidv4} from "uuid";
import {ChunkListService} from "../chunk-list/chunk-list.service";
import {RenovationStatus} from "../renovation/renovation";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MapElementService {
  editableMapElement: MapElement|null = null;

  constructor(
    private chunkListService: ChunkListService,
    private http: HttpClient
  ) {}

  createMapElement(uniqueId: string, chunkId: string, category: Category | null, projection: Projection | null): MapElement {
    const chunk = this.chunkListService.getChunkById(chunkId);

    return {
      id: uuidv4(),
      uniqueId: uniqueId + '_' + this.getProjectionString(projection),
      chunk: chunk,
      category: category,
      projection: projection !== null ? Number(projection) : null,
      status: RenovationStatus.PENDING,
      skinRenovationId: 1,
      createdAt: new Date(),
      updatedAt: null
    }
  }

  getProjectionString(projection: Projection | null) {
    switch (Number(projection)) {
      case Projection.LEFT:
        return 'left'
      case Projection.RIGHT:
        return 'right'
      case Projection.TOP:
        return 'top'
      case Projection.DOWN:
        return 'down'
      default:
        return 'free'
    }
  }

  savePendingMapElement(mapElement: MapElement): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/skin-renovations/map-element-create', mapElement, {
      observe: 'response',
      withCredentials: true
    });
  }

  updateMapElement(mapElement: MapElement): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/skin-renovations/map-element/' + mapElement.id + '/update', mapElement, {
      observe: 'response',
      withCredentials: true
    });
  }

  deleteMapElement(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/skin-renovations/map-element/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }
}
