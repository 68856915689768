import {Injectable} from '@angular/core';
import {PaginationService} from '../../common/pagination-service';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {RaceCompetitionConfig} from '../race-competition-config/race-competition-config';

@Injectable({
  providedIn: 'root'
})
export class RaceCompetitionConfigListService extends PaginationService {
  private _raceCompetitionConfigs$ = new Subject<RaceCompetitionConfig[]>();

  constructor(private http: HttpClient) {
    super(0, 10000, 10000);
  }

  setRaceCompetitionConfigs(configs: RaceCompetitionConfig[]) {
    this._raceCompetitionConfigs$.next(configs);
  }

  get raceCompetitionConfigs$(): Subject<RaceCompetitionConfig[]> {
    return this._raceCompetitionConfigs$;
  }

  getRaceCompetitionConfigList(): Observable<HttpResponse<RaceCompetitionConfig[]>> {
    return this.http.get<RaceCompetitionConfig[]>('/api/v1/ui/race-competition-config-list', {
      headers: new HttpHeaders({
        'Range': 'race-competition-configs=' + this.from + '-' + this.to,
      }),
      params: {
        from: this.from.toString(),
        to: this.to.toString(),
        orderBy: this.orderByColumn,
        orderDirection: this.orderDirection
      },
      observe: 'response',
      withCredentials: true
    });
  }
}
