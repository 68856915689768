import {Chunk} from "../chunk/chunk";
import {RenovationStatus} from "../renovation/renovation";

export interface MapElement {
  id: string
  uniqueId: string
  projection: Projection|null
  category: Category | null;
  chunk: Chunk|null
  status: RenovationStatus;
  skinRenovationId: number
  createdAt: Date
  updatedAt: Date|null
}

export interface MapElementDto {
  uniqueId: string
  allowedSkinIds: string[]
  projection: Projection|null
}

export enum Projection {
  FREE = 0,
  LEFT = 1,
  RIGHT = 2,
  TOP = 3,
  DOWN = 4,
}

export enum Category {
  None = 0,
  Accent_Piece = 27,
  Armchair = 1,
  Bra = 2,
  Carpet = 3,
  Chair = 4,
  Console = 5,
  Flower = 6,
  Floor = 14,
  Garden_arch = 22,
  Garden_decor = 23,
  Garden_lamp = 24,
  Hanger = 16,
  Lamp = 7,
  Mirror = 8,
  Ottoman = 17,
  Piano = 13,
  Rattan_Chair = 26,
  Shelf_decor = 18,
  Sofa = 9,
  Statue = 10,
  Stairs = 12,
  Table = 11,
  Table_decor = 19,
  Tree = 25,
  Wall = 15,
  Wall_art = 20,
  Wall_lamp = 21
}
