import {Component, OnInit} from '@angular/core';
import {RenovationService} from "../renovation/renovation.service";
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SetListService} from "../set-list/set-list.service";
import {SetService} from "./set.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {allowedCharactersValidatorForUniqueId} from "../../../form-validators/validators";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {NgIf} from '@angular/common';
import {InternalNotificationService} from '../../../functions/notification';

@Component({
  selector: 'app-set',
  templateUrl: './set.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule
  ],
  styleUrls: ['./set.component.scss']
})
export class SetComponent implements OnInit {
  setForm = new FormGroup({
    uniqueId: new FormControl(null, [Validators.maxLength(128), allowedCharactersValidatorForUniqueId()]),
    name:     new FormControl(null, [Validators.required, Validators.maxLength(128)]),
  });

  constructor(
    private internalNotificationService: InternalNotificationService,
    public renovationService: RenovationService,
    public renovationListService: RenovationListService,
    public setListService: SetListService,
    public setService: SetService,
  ) {}

  ngOnInit(): void {
    if (this.setService.editableSet) {
      if (!this.setForm.dirty) {
        this.setForm.reset({
          uniqueId: this.setService.editableSet.uniqueId,
          name: this.setService.editableSet.name,
        });
        this.setForm.markAsDirty()
      }
    }
  }

  save() {
    if (this.setForm.valid) {
      const set = this.setService.createSet(
        this.setForm.controls['uniqueId'].value,
        this.setForm.controls['name'].value,
      );

      this.setService.savePendingSet(set).subscribe({
        next: (data) => {
          if (data.status === 201) {
            this.setListService.addPendingSet(set)
            this.reset()
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, "Невдалося додати сет в очікування");
        }
      })
    }
  }

  update(): void {
    if (this.setService.editableSet && this.setForm.valid) {
      const modifiedSet = {
        ...this.setService.editableSet,
        uniqueId: this.setForm.controls.uniqueId.value,
        name: this.setForm.controls.name.value,
      };

      this.setService.updateSet(modifiedSet).subscribe({
        next: (data) => {
          if (data.status === 201) {
            this.internalNotificationService.showSuccess('Сет змінено');
            this.renovationListService.fetchAndLoadSkinRenovations()
            this.reset()
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, "Невдалося оновити сет");
        }
      })
    }
  }

  reset() {
    if (this.setForm.dirty) {
      this.setService.editableSet = null
      this.setForm.reset({
        uniqueId: null,
        name: null,
      });
    }
  }

  // isEditableSetEqualToFormValues(): boolean {
  //   return this.setService.editableSet?.uniqueId === this.setForm.controls.uniqueId.value
  //     && this.setService.editableSet?.name === this.setForm.controls.name.value
  // }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }
}
