<div [formGroup]="raceCompetitionConfigForm" (submit)="save()">
  <div>
    <h3 class="font-medium text-gray-900 p-2.5 mt-2 mb-2">Конфіг змагань на перегони</h3>
    <div>
      <div class="block w-full">
        <input
          formControlName="name"
          id="11"
          type="text"
          placeholder="Назва"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.name)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full {{getDropdownStyles(this.raceCompetitionConfigForm.controls.gameMode)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          bindValue="value"
          bindLabel="label"
          formControlName="gameMode"
          placeholder="{{raceCompetitionConfigForm.controls.gameMode.value === null ? 'Тип змагань' : ''}}"
          class="custom-select block w-full text-sm text-gray-500">
          <ng-option class="focus:border-blue-300 focus:ring-blue-200" *ngFor="let gameMode of GameModeType"
                     [value]="gameMode.value">{{gameMode.label}}
          </ng-option>
        </ng-select>
      </div>
      <div class="block w-full">
        <input
          formControlName="secondsToSearch"
          id="22"
          type="text"
          placeholder="Секунд на пошук `гравців`"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.secondsToSearch)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="secondsToStart"
          id="33"
          type="number"
          placeholder="Секунд до старту змагань"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.secondsToStart)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="secondsStepInterval"
          id="44"
          type="number"
          placeholder="Секундний інтервал шагу ботів"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.secondsStepInterval)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          (keyup)="onChangeMaxStepsOrMaxScore()"
          formControlName="maxSteps"
          id="66"
          type="number"
          placeholder="Кількість шагів / Max steps"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.maxSteps)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          (keyup)="onChangeMaxStepsOrMaxScore()"
          formControlName="maxScore"
          id="55"
          type="number"
          placeholder="Мета змагань / Max score"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.maxScore)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="botConfig"
          id="99"
          type="text"
          placeholder="Bot config / json"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.botConfig)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="eventConfig"
          id="111"
          type="text"
          placeholder="Event config / json"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.eventConfig)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="nicknameConfig"
          id="122"
          type="text"
          placeholder="Nickname config / json"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.nicknameConfig)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="avatarConfig"
          id="133"
          type="text"
          placeholder="Avatar config / json"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.avatarConfig)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="frameConfig"
          id="144"
          type="text"
          placeholder="Frame config / json"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.raceCompetitionConfigForm.controls.frameConfig)}} focus:ring focus:ring-opacity-50">
      </div>
    </div>
  </div>
  <div class="flex">
    <button *ngIf="this.raceCompetitionConfigForm.valid && isEditingRaceCompetitionConfigNotEqualToFormValues()" type="submit" (click)="save()" class="flex-1 mt-2 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500">Зберегти</button>
    <button *ngIf="this.raceCompetitionConfigForm.dirty" type="button" (click)="reset()" class="flex-1 mt-2 ml-3 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Відмінити</button>
  </div>
</div>
