import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function allowedCharactersValidatorForUniqueId(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const allowedPattern = /^[a-z0-9_-]*$/;
    const valid = allowedPattern.test(control.value);
    return valid ? null : { 'invalidCharacters': { value: control.value } };
  };
}

export function appVersionValidator(control: FormControl): { [key: string]: boolean } | null {
  const versionRegex = /^\d+\.\d+\.\d+$/;
  if (control.value && !versionRegex.test(control.value)) {
    return { 'invalidVersion': true };
  }
  return null;
}

export function customHttpsValidator(control: AbstractControl): ValidationErrors | null {
  const url = control.value;

  if (url && !url.startsWith('https://')) {
    return { invalidHttps: true };
  }

  return null;
}

export function datetimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const datetime = Date.parse(control.value);
    return (datetime > 0 && !isNaN(datetime)) || control.value?.trim() === ''
      ? null
      : {invalidDatetime: {value: control.value}};
  };
}

export function bigIntValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value >= Number.MIN_SAFE_INTEGER && control.value <= Number.MAX_SAFE_INTEGER && control.value % 1 === 0 && control.value != 0
      ? null
      : {invalidBigInt: {value: control.value}};
  };
}

export function intValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null) {
      return {invalidInt: {value: control.value}};
    }

    let value: number;

    if (typeof control.value === 'string') {
      const valueWithoutFirstAssignment = control.value.replace("=", "");
      value = Number(valueWithoutFirstAssignment);
    } else {
      value = Number(control.value);
    }

    return value >= -2147483648 && value <= 2147483647 && value % 1 === 0 && value != 0
      ? null
      : {invalidInt: {value: control.value}};
  };
}

export function floatValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let floatValue = Number(String(control.value).replace(',', '.'));
    const isPositiveOrNegativeFloatSectionsValue = Math.sign(Number(String(floatValue).replace(',', '.')));

    if (isPositiveOrNegativeFloatSectionsValue === 1 || isPositiveOrNegativeFloatSectionsValue === -1 || Number(String(floatValue).replace(',', '.')) === 0) {
      return null;
    }

    return {invalidFloat: {value: control.value}};
  };
}

export function fileTypeValidator(allowedTypes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    const extension = value.split('.').pop()?.toLowerCase();

    if (!extension || !allowedTypes.includes(`.${extension}`)) {
      return { fileType: { allowedTypes } };
    }
    return null;
  };
}

export function jsonValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;

    try {
      if (typeof value === 'string') {
        JSON.parse(value);
      } else if (value instanceof File) {
        return { json: { message: 'Cannot validate File asynchronously in sync validator.' } };
      }
    } catch {
      return { json: { message: 'Invalid JSON format.' } };
    }
    return null;
  };
}
