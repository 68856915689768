import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserLoginComponent} from "./users/user-login/user-login.component";
import {UserRegistrationComponent} from "./users/user-registration/user-registration.component";
import {UserForgotPasswordComponent} from "./users/user-forgot-password/user-forgot-password.component";
import {UserListComponent} from "./users/user-list/user-list.component";
import {ProfileCommonComponent} from "./players/profiles/profile-common/profile-common.component";
import {UserProfileComponent} from "./users/user-profile/user-profile.component";
import {UserSettingsComponent} from "./users/user-settings/user-settings.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {ApplicationLogsListComponent} from "./security/application-logs-list/application-logs-list.component";
import {ApplicationLogComponent} from "./security/application-log/application-log.component";
import {DeployListComponent} from "./vq/deploy-list/deploy-list.component";
import {SaveListComponent} from "./vq/save-list/save-list.component";
import {ProfileComponent} from "./vq/profile/profile.component";
import {DeployComponent} from "./vq/deploy/deploy.component";
import {PageTypeEnum} from "./common/page";
import {RenovationListComponent} from "./vq/skin-renovations/renovation-list/renovation-list.component";
import {RemoteConfigListComponent} from "./vq/remote-config-list/remote-config-list.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {
  RaceCompetitionConfigListComponent
} from './vq/race-competition-config-list/race-competition-config-list.component';

export const routes: Routes = [
  {path: 'application-log-list', component: ApplicationLogsListComponent},
  {path: 'application-log/:filename/:requestId', component: ApplicationLogComponent},
  {path: '', component: DashboardComponent},
  {path: 'login', component: UserLoginComponent},
  {path: 'registration', component: UserRegistrationComponent},
  {path: 'forgot-password', component: UserForgotPasswordComponent},
  {path: 'user-list', component: UserListComponent, data: {'type': PageTypeEnum.USER_LIST}},
  {path: 'user-settings', component: UserSettingsComponent, data: {'type': PageTypeEnum.USER_SETTINGS}},
  {path: 'user/:id/profile', component: UserProfileComponent, data: {'type': PageTypeEnum.USER_EDIT}},
  {path: 'save-list', component: SaveListComponent, data: {'type': PageTypeEnum.SAVE_LIST}},
  {path: 'cheat-save-list', component: SaveListComponent, data: {'type': PageTypeEnum.CHEAT_SAVE_LIST}},
  {path: 'cheat-save-create/cheat-save-list', component: SaveListComponent, data: {'type': PageTypeEnum.CHEAT_SAVE_CREATE}},
  {path: 'cheat-save-edit/:id/cheat-save-list', component: SaveListComponent, data: {'type': PageTypeEnum.CHEAT_SAVE_EDIT}},
  {path: 'deploy/:id/save-list', component: SaveListComponent, data: {'type': PageTypeEnum.DEPLOY_EDIT}},
  {path: 'deploy-create/save-list', component: SaveListComponent, data: {'type': PageTypeEnum.DEPLOY_CREATE}},
  {path: 'offer/:id/save-list', component: SaveListComponent, data: {'type': PageTypeEnum.OFFER_EDIT}},
  {path: 'offer-create/save-list', component: SaveListComponent, data: {'type': PageTypeEnum.OFFER_CREATE}},
  {path: 'renovation-create/renovation-list', component: RenovationListComponent, data: {'type': PageTypeEnum.RENOVATION_CREATE}},
  {path: 'renovation-list', component: RenovationListComponent, data: {'type': PageTypeEnum.RENOVATION_LIST}},
  {path: 'remote-config-edit/:id/remote-config-list', component: RemoteConfigListComponent, data: {'type': PageTypeEnum.REMOTE_CONFIG_EDIT}},
  {path: 'remote-config-create/remote-config-list', component: RemoteConfigListComponent, data: {'type': PageTypeEnum.REMOTE_CONFIG_CREATE}},
  {path: 'remote-config-list', component: RemoteConfigListComponent, data: {'type': PageTypeEnum.REMOTE_CONFIG_LIST}},
  {path: 'race-competition-config-edit/:id/config-list', component: RaceCompetitionConfigListComponent, data: {'type': PageTypeEnum.RACE_COMPETITION_CONFIG_EDIT}},
  {path: 'race-competition-config-create/config-list', component: RaceCompetitionConfigListComponent, data: {'type': PageTypeEnum.RACE_COMPETITION_CONFIG_CREATE}},
  {path: 'race-competition-config-list', component: RaceCompetitionConfigListComponent, data: {'type': PageTypeEnum.RACE_COMPETITION_CONFIG_LIST}},
  {path: 'player/:id/profile/common', component: ProfileCommonComponent},
  // {path: 'player/:id/profile/victorian-quest', component: ProfileVictorianQuestComponent},
  {path: 'profile/:id', component: ProfileComponent},
  {path: 'deploy-list', component: DeployListComponent, data: {'type': PageTypeEnum.DEPLOY_LIST}},
  {path: 'deploy/:id', component: DeployComponent},                                                 // TODO ??
  // {path: 'sections-values/mapping', component: SectionsValuesMappingComponent},
  {path: 'page-not-found', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/page-not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
