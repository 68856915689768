import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {RaceCompetitionConfig} from './race-competition-config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RaceCompetitionConfigService {
  constructor(private http: HttpClient) {}

  getRaceCompetitionConfig(id: number): Observable<HttpResponse<RaceCompetitionConfig>> {
    return this.http.get<RaceCompetitionConfig>('/api/v1/ui/race-competition-config/' + id, {
      observe: 'response',
      withCredentials: true
    });
  }

  createRaceCompetitionConfig(config: RaceCompetitionConfig): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/race-competition-config/create', config, {
      observe: 'response',
      withCredentials: true
    });
  }

  updateRaceCompetitionConfig(id: number, config: RaceCompetitionConfig): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/race-competition-config/' + id + '/update', config, {
      observe: 'response',
      withCredentials: true
    });
  }

  deleteRaceCompetitionConfig(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/race-competition-config/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }

}
