import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {AuthorizationService} from "./authorization.service";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {PermissionService} from "./permission.service";
import {Router} from "@angular/router";
import {InternalNotificationService} from '../functions/notification';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authorizationService: AuthorizationService,
    private internalNotificationService: InternalNotificationService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => {},
        error => {
          if (error.status === 401) {
            this.internalNotificationService.showError('Увійдіть в систему заново')
            this.authorizationService.removeId();
            this.authorizationService.removeToken();
            this.permissionService.removePermissions();
            this.authorizationService.isStillAuthenticated();

            setTimeout(() => {
              //TODO show login modal https://tailwindui.com/components/ecommerce/components/product-quickviews
              this.router.navigateByUrl('/login');
            }, 1500);
          }
        }
      )
    );
    // return next.handle(request).pipe((event: any) => {
    //   if (event instanceof HttpResponse) {
    //     // do stuff with response if you want
    //   }
    // }, (err: any) => {
    //   if (err instanceof HttpErrorResponse) {
    //     if (err.status === 401) {
    //       // redirect to the login route
    //       // or show a modal
    //     }
    //   }
    // });
  }
}
