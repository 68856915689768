export interface RaceCompetitionConfig {
  id: number
  name: string
  gameMode: GameMode
  secondsToSearch: number
  secondsToStart: number
  secondsStepInterval: number
  maxScore: number
  maxSteps: number
  maxPlayers: number // can't be changed
  maxBots: number // can't be changed
  botConfig: string // count must be >= maxBots
  eventConfig: string // count must be >= maxBots
  nicknameConfig: string // count must be >= then maxBots
  avatarConfig: string // count must be >= then maxBots
  frameConfig: string // count must be >= then maxBots
  createdAt: Date
  updatedAt: Date|null
  userId: number
  username: string
}

export const MaxPvEPlayers = 1
export const MaxPvEBots = 4

export enum GameMode {
  PvE = 'PvE',
  PvP = 'PvP',
  PvPvE = 'PvPvE',
}
