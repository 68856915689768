import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, RouterLink, RouterLinkActive} from "@angular/router";
import {DeployService} from "./deploy.service";
import {Deploy, DeployState, Redeem, TestPlayer} from "./deploy";
import {OperationType, ValueType} from "../profile/profile";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {trans} from "../../../translations";
import {AsyncPipe, DatePipe, KeyValuePipe, NgForOf, NgIf} from '@angular/common';
import {NgOptionComponent, NgSelectComponent} from '@ng-select/ng-select';
import {InternalNotificationService} from '../../functions/notification';
import {SkinService} from '../skin-renovations/skin/skin.service';
import {customHttpsValidator, datetimeValidator, floatValidator, intValidator} from '../../form-validators/validators';

@Component({
  selector: 'app-deploy',
  templateUrl: './deploy.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    NgOptionComponent,
    NgForOf,
    DatePipe,
    RouterLink,
    RouterLinkActive,
    AsyncPipe,
    NgSelectComponent,
    KeyValuePipe
  ],
  styleUrls: ['./deploy.component.scss']
})
export class DeployComponent implements OnInit {

  deploy$: Observable<Deploy>;
  removedRedeemsFromDeploy: number[] = [];
  isDeployApplyingFailed: boolean|null = null;
  isDeployRevertingFailed: boolean|null = null;
  redeemDuplicates = new Map<string, number[]>();
  conflictedSectionsToReverting: object[]|null = null;
  isEditModeEnabledDeployActiveTime: boolean = false;
  isEditModeEnabledDeployDetails: boolean = false;
  templatesIds: number[] = [1, 2, 3];                 // TODO доделать темплейты когда добавят художники

  redeemNameTypeMap = new Map<string, string>([
    ['coin', 'int'],
    ['ruby', 'int'],
    ['energy', 'int'],
    ['stars', 'int'],
    ['experience', 'int'],
    ['event cup', 'int'],
    ['reagent instrument', 'int'],
    ['magnifier instrument', 'int'],
    ['foto instrument', 'int'],
    ['timer instrument', 'int'],
    ['history resource', 'int'],
    ['gallery entry 01', 'int'],
    ['gallery entry 02', 'int'],
    ['gallery entry 03', 'int'],
    ['gallery entry 04', 'int'],
    ['gallery entry 05', 'int'],
    ['gallery entry 06', 'int'],
    ['gallery entry 07', 'int'],
    ['gallery entry 08', 'int'],
    ['gallery entry 09', 'int'],
    ['gallery entry 10', 'int'],
  ]);

  redeemNameIdMap = new Map<string, string>([
    ['coin', 'CURRENCY_COIN'],
    ['ruby', 'CURRENCY_RUBY'],
    ['energy', 'CURRENCY_ENERGY'],
    ['stars', 'CURRENCY_STARS'],
    ['experience', 'CURRENCY_EXPERIENCE'],
    ['event cup', 'CURRENCY_EVENT_CUP'],
    ['reagent instrument', 'CURRENCY_REAGENT_INSTRUMENT'],
    ['magnifier instrument', 'CURRENCY_MAGNIFIER_INSTRUMENT'],
    ['foto instrument', 'CURRENCY_FOTO_INSTRUMENT'],
    ['timer instrument', 'CURRENCY_TIMER_INSTRUMENT'],
    ['history resource', 'CURRENCY_HISTORY_RESOURCE'],
    ['gallery entry 01', 'gallery_entry_01'],
    ['gallery entry 02', 'gallery_entry_02'],
    ['gallery entry 03', 'gallery_entry_03'],
    ['gallery entry 04', 'gallery_entry_04'],
    ['gallery entry 05', 'gallery_entry_05'],
    ['gallery entry 06', 'gallery_entry_06'],
    ['gallery entry 07', 'gallery_entry_07'],
    ['gallery entry 08', 'gallery_entry_08'],
    ['gallery entry 09', 'gallery_entry_09'],
    ['gallery entry 10', 'gallery_entry_10'],
  ]);

  selectedOperationType: OperationType|null = null;
  selectedSectionsValueId: number|null = null;

  inputType: string = "text";
  inputPlaceholder: string = trans('Change count');

  gameValuesFormDefaults = {
    name: trans('Select item'),
    value: null,
  }

  deployActiveTimeForm = new FormGroup({
    'activeTimeFrom': new FormControl(null, [datetimeValidator()]),
    'activeTimeTo':   new FormControl(null, [datetimeValidator()]),
  });

  addTestPlayerForm = new FormGroup({
    'playerId': new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(36)]),
  });

  deployDetailsForm = new FormGroup({
    'name':         new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    'description':  new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(128)]),
    'applyText':    new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    'hyperLink':    new FormControl(null, [Validators.minLength(8), Validators.maxLength(128), customHttpsValidator]),
    'templateId':   new FormControl(1, [Validators.required]),
  });

  gameValuesForm = new FormGroup({
    'name':          new FormControl(this.gameValuesFormDefaults.name, []),
    'value':         new FormControl(this.gameValuesFormDefaults.value, []),
  });

  changeStatusForm = new FormGroup({
    'reason': new FormControl('', [Validators.required, Validators.minLength(2)]),
  });

  currentDatetimeUtc: Date;

  constructor(
    private deployService: DeployService,
    private internalNotificationService: InternalNotificationService,
    private route: ActivatedRoute,
    private skinService: SkinService,
    // private clipboard: Clipboard
  ) {
    this.deploy$ = this.deployService.deploy$;
    this.currentDatetimeUtc = new Date();
    setInterval(() => {
      this.currentDatetimeUtc = new Date();
    }, 1000);
  }

  ngOnInit(): void {
    this.fetchDeploy();
    this.fetchSkins();
  }

  findDuplicatesInRedeems(redeems: Redeem[]|null): void {
    let i = 0;

    for (const redeem of redeems ?? []) {
      const redeemDuplicatesContainer = this.redeemDuplicates.get(redeem.id);

      if (redeemDuplicatesContainer) {
        redeemDuplicatesContainer.push(i);
        this.redeemDuplicates.set(redeem.id, redeemDuplicatesContainer);
      } else {
        const redeemDuplicatesContainer: number[] = [];
        redeemDuplicatesContainer.push(i)
        this.redeemDuplicates.set(redeem.id, redeemDuplicatesContainer);
      }

      i++;
    }
  }

  fetchDeploy(): void {
    const deployId = this.route.snapshot.paramMap.get('id');

    if (deployId) {
      this.deployService.getDeploy(Number(deployId)).subscribe(
        (data) => {
          if (data.body !== null) {
            this.deployService.setDeploy(data.body);
            this.deployDetailsForm.patchValue({
              name: data.body.name,
              description: data.body.description,
              applyText: data.body.applyText,
              hyperLink: data.body.hyperLink,
              templateId: data.body.templateId
            })
            this.redeemDuplicates = new Map<string, number[]>();
            this.findDuplicatesInRedeems(data.body.redeems);
          }
        });
    }
  }

  fetchSkins(): void {
    this.skinService.getAllSKinsForRedeems().subscribe(
      (data) => {
        if (data.body !== null) {
          for (const skin of data.body) {
            if (skin.uniqueId) {
              this.redeemNameTypeMap.set(skin.uniqueId, 'int');
              this.redeemNameIdMap.set(skin.uniqueId, skin.uniqueId);
            }
          }
        }
      });
  }

  getBackgroundColorForDeployState(state: DeployState): string {
    switch (state) {
      case DeployState.initial: return 'blue'
      case DeployState.applied: return 'green'
      case DeployState.declined: return 'red'
      case DeployState.reverted: return 'yellow'
      case DeployState.deleted: return 'red'
    }
  }

  getBackgroundColorForPlayersCount(players: string[]|null): string {
    return players === null ? 'red' : 'blue';
  }

  isApplicable(state: DeployState): boolean {
    return state !== DeployState.deleted && (state === DeployState.initial || state === DeployState.declined);
  }

  isDeclinable(state: DeployState): boolean {
    return state !== DeployState.deleted && state === DeployState.applied;
  }

  isRevertable(state: DeployState): boolean {
    return state !== DeployState.deleted && state === DeployState.applied;
  }

  isDeletable(state: DeployState): boolean {
    return state !== DeployState.deleted;
  }

  removeSelectedRedeemsFromDeploy(deploy: Deploy) {
    this.isDeployApplyingFailed = false;

    const removedRedeemsFromDeploy = this.removedRedeemsFromDeploy;
    const redeems = deploy.redeems?.filter(function(value, index){
      return !removedRedeemsFromDeploy.includes(index);
    });

    this.deployService.replaceDeployRedeems(deploy.id, {
      'redeems': redeems
    }).subscribe(
      (data) => {
        if (data.status === 200) {
          this.removedRedeemsFromDeploy = [];
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Вибрані редіми видалені')
        }
      }
    );
  }

  isRedeemCanBeRemoved(state: DeployState): boolean {
    return state === DeployState.initial;
  }

  removeSelectedRedeem(state: DeployState, index: number) {
    if (!this.isRedeemCanBeRemoved(state)) {
      return;
    }

    this.isDeployApplyingFailed = false;

    if (this.removedRedeemsFromDeploy.includes(index)) {
      this.removedRedeemsFromDeploy = this.removedRedeemsFromDeploy.filter(function(value){
        return value !== index;
      });

      return;
    }

    this.removedRedeemsFromDeploy.push(index);
  }

  isStateInitial(state: DeployState): boolean {
    return state === DeployState.initial;
  }

  applyDeploy(deploy: Deploy) {
    if (!confirm('Ви впевнені що хочете задеплоїти?')) {
      return;
    }

    this.deployService.changeDeployState(deploy.id, DeployState.applied).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.fetchDeploy();
          this.isDeployApplyingFailed = false;
          this.internalNotificationService.showSuccess('Деплой застосований')
        }
      },
      error: (error) => {
        this.fetchDeploy();
        this.isDeployApplyingFailed = true;
        this.internalNotificationService.handleError(error, 'Не вдалося застосувати даний деплой');
      }
    })
  }

  declineDeploy(deploy: Deploy) {
    if (!confirm('Ви впевнені що хочете відмінити деплой?')) {
      return;
    }

    this.deployService.changeDeployState(deploy.id, DeployState.declined).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Деплой відмінений')
        }
      },
      error: (error) => {
        this.fetchDeploy();
        this.internalNotificationService.handleError(error, 'Не вдалося відмінити даний деплой');
      }
    })
  }

  deleteDeploy(deploy: Deploy) {
    if (!confirm('Ви впевнені що хочете видалити деплой?')) {
      return;
    }

    this.deployService.changeDeployState(deploy.id, DeployState.deleted).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Деплой видалений')
        }
      },
      error: (error) => {
        this.fetchDeploy();
        this.internalNotificationService.handleError(error, 'Не вдалося видалити даний деплой');
      }
    })
  }

  getRedeemStatus(redeem: Redeem, chosenRedeemIndex: number): string {
    const redeemDuplicatesContainer = this.redeemDuplicates.get(redeem.id);

    if (redeemDuplicatesContainer !== undefined && redeemDuplicatesContainer.length > 1) {
      return 'Дублікат редіма: ' + redeem.name;
    }

    return '';
  }

  redeemsContainsDuplicates(): boolean {
    for (let [key, value] of this.redeemDuplicates) {
      if (value.length > 1) {
        return true;
      }
    }
    return false;
  }

  getSectionsValueStatusBackgroundColor(redeem: Redeem, redeemIndex: number): string {
    if (this.removedRedeemsFromDeploy.includes(redeemIndex)) {
      return 'bg-red-50';
    }

    const redeemDuplicatesContainer = this.redeemDuplicates.get(redeem.id);

    if (redeemDuplicatesContainer !== undefined && redeemDuplicatesContainer.length > 1) {
        return 'bg-yellow-50';
    }

    return '';
  }

  addTestPlayerToDeploy(deploy: Deploy) {
    if (this.addTestPlayerForm.invalid) {
      return;
    }

    const newTestPlayerId = this.addTestPlayerForm.controls.playerId.value.trim();

    for (const testPlayer of deploy.testPlayers ?? []) {
      if (newTestPlayerId === testPlayer.playerId) {
        this.internalNotificationService.showError('Тестовий гравець вже доданий')

        return;
      }
    }

    this.deployService.addTestPlayer(deploy.id, newTestPlayerId).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Тестового гравця додано')
        }
      },
      error: (error) => {
        this.fetchDeploy();

        if (error.status === 404) {
          this.internalNotificationService.handleError(error, error.error);
        } else {
          this.internalNotificationService.handleError(error, 'Не вдалося додати тестового гравця');
        }
      }
    })
  }

  retestPlayer(deploy: Deploy, testPlayer: TestPlayer) {
    this.deployService.retestPlayer(deploy.id, testPlayer.playerId).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Гравець буде заново протестований')
        }
      },
      error: (error) => {
        this.fetchDeploy();

        if (error.status === 404) {
          this.internalNotificationService.handleError(error, error.error);
        } else {
          this.internalNotificationService.handleError(error, 'Не вдалося заново протестувати гравця');
        }
      }
    })
  }

  isChangedDeployActiveTimeForm(deploy: Deploy): boolean {
    const formValues = {...this.deployActiveTimeForm.value};
    const activeTimeFrom: number|null = formValues.activeTimeFrom === null ? null : Date.parse(formValues.activeTimeFrom);
    const activeTimeTo: number|null = formValues.activeTimeTo === null ? null : Date.parse(formValues.activeTimeTo);
    console.log(activeTimeFrom, activeTimeTo);

    if (activeTimeFrom && deploy.activeTimeFrom && activeTimeFrom !== deploy.activeTimeFrom.getTime()) {
      return true;
    }

    if (activeTimeTo && deploy.activeTimeTo && activeTimeTo !== deploy.activeTimeTo.getTime()) {
      return true;
    }

    return false;
  }

  isValidActiveTime() {
    const activeTimeFrom = Date.parse(this.deployActiveTimeForm.controls.activeTimeFrom.value)
    const activeTimeTo = Date.parse(this.deployActiveTimeForm.controls.activeTimeTo.value)
    console.log(activeTimeFrom, activeTimeTo);

    if (activeTimeFrom && activeTimeTo && activeTimeFrom >= activeTimeTo) {
      this.deployActiveTimeForm.controls.activeTimeTo.setErrors({'incorrect': true})
    }
  }

  changeDeployDetails(deploy: Deploy) {
    if (this.deployDetailsForm.invalid) {
      return;
    }

    this.deployService.replaceDeployDetails(deploy.id, {...this.deployDetailsForm.value}).subscribe(
      (data) => {
        if (data.status === 200) {
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Деталі деплоя змінено')
          this.isEditModeEnabledDeployDetails = !this.isEditModeEnabledDeployDetails;
        }
      }
    );
  }

  changeDeployActiveTime(deploy: Deploy) {
    if (this.deployActiveTimeForm.invalid) {
      return;
    }

    this.deployService.replaceDeployActiveTime(deploy.id, {
      'activeTimeFrom': this.deployActiveTimeForm.controls.activeTimeFrom.value,
      'activeTimeTo':   this.deployActiveTimeForm.controls.activeTimeTo.value
    }).subscribe(
      (data) => {
        if (data.status === 200) {
          this.removedRedeemsFromDeploy = [];
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Період роботи деплоя оновлено')
          this.isEditModeEnabledDeployActiveTime = !this.isEditModeEnabledDeployActiveTime;
        }
      }
    );
  }

  addRedeemToDeploy(deploy: Deploy): void {
    let formValues = {...this.gameValuesForm.value};
    let redeemList = deploy.redeems as Redeem[];

    const newRedeem = {
      id: this.redeemNameIdMap.get(formValues.name),
      name: formValues.name,
      type: this.getRedeemTypeByNameId(formValues.name),
      value: formValues.value
    } as Redeem

    if (redeemList === null) {
      redeemList = [];
    }

    redeemList.push(newRedeem);

    this.deployService.replaceDeployRedeems(deploy.id, {
      'redeems': redeemList
    }).subscribe(
      (data) => {
        if (data.status === 200) {
          this.removedRedeemsFromDeploy = [];
          this.fetchDeploy();
          this.internalNotificationService.showSuccess('Додано редім до деплоя')
          this.resetForm();
        }
      }
    );
  }

  private getRedeemTypeByNameId(name: string): number {
    if (name.includes('gallery')) {
      return 5;
    }

    if (name.includes('skin')) {
      return 1;
    }

    return 0;
  }

  private setBooleanInput() {
    this.inputType = '';
    this.inputPlaceholder = '';
  }

  private setStringInput() {
    this.inputType = "text";
    this.inputPlaceholder = 'Додайте текст';
  }

  private setNumberInput() {
    this.inputType = "text";
    this.inputPlaceholder = 'Змінити кількість';
  }

  private setDatetimeInput() {
    this.inputType = "text";
    this.inputPlaceholder = 'yyyy-mm-dd hh:mm:ss'
  }

  isShowingSaveButton(): boolean {
    let formValues = {...this.gameValuesForm.value};
    let gameValueType = null;

    if (this.redeemNameTypeMap.has(formValues.name)) {
      gameValueType = this.redeemNameTypeMap.get(formValues.name)
    }

    if (formValues.value === this.gameValuesFormDefaults.value) {
      return false;
    }

    switch (gameValueType) {
      case ValueType.BOOL:
        if (formValues.value === undefined || formValues.value === null) {
          this.gameValuesForm.controls['value'].patchValue(false);
        }

        return true;
      case ValueType.BINARY:
        return true;
      case ValueType.DATETIME:
        this.gameValuesForm.controls['value'].updateValueAndValidity();
        return !this.gameValuesForm.controls['value'].invalid
      case ValueType.FLOAT:
        this.setNumberInput();
        this.gameValuesForm.controls['value'].addValidators(floatValidator());
        this.gameValuesForm.controls['value'].updateValueAndValidity();
        const isPositiveOrNegativeFloatValue = Math.sign(Number(String(formValues.value).replace(',', '.')));

        if (Number(String(formValues.value).replace(',', '.')) == 0) {
          return false;
        }

        if (isPositiveOrNegativeFloatValue === 1 || isPositiveOrNegativeFloatValue === -1) {
          return !this.gameValuesForm.controls['value'].invalid
        }

        break;
      case ValueType.INT64:
      case ValueType.INT:
        this.setNumberInput();
        this.gameValuesForm.controls['value'].addValidators(intValidator());
        this.gameValuesForm.controls['value'].updateValueAndValidity();

        const valueWithoutFirstAssignment = formValues.value.replace("=", "");
        const value = Number(valueWithoutFirstAssignment);

        if (Number(value) == 0) {
          return false;
        }

        const isPositiveOrNegativeValue = Math.sign(value);

        if (isPositiveOrNegativeValue === 1 || isPositiveOrNegativeValue === -1 ) {
          return !this.gameValuesForm.controls['value'].invalid
        }

        break;
      case ValueType.STRING:
        return !this.gameValuesForm.controls['value'].invalid;
      default:
        return false;
    }

    return false;
  }

  isShowingDeclineButton(): boolean {
    const formValues = {...this.gameValuesForm.value};

    return formValues.name !== this.gameValuesFormDefaults.name
       || formValues.value !== this.gameValuesFormDefaults.value
  }

  isSelectedItem(): boolean {
    const formValues = {...this.gameValuesForm.value};

    return formValues.name !== this.gameValuesFormDefaults.name;
  }

  resetForm() {
    this.setNumberInput();
    this.gameValuesForm.reset(this.gameValuesFormDefaults);
  }

  isValidValue(): boolean {
    this.gameValuesForm.controls['value'].updateValueAndValidity();

    return this.gameValuesForm.controls['value'].invalid
      && (this.gameValuesForm.controls['value'].touched || this.gameValuesForm.controls['value'].dirty);
  }

  isChangedDeployDetailsForm(deploy: Deploy) {
    const formValues = {...this.deployDetailsForm.value};
    return formValues.name !== deploy.name
      || formValues.description !== deploy.description
      || formValues.applyText !== deploy.applyText
      || formValues.hyperLink !== deploy.hyperLink
      || formValues.templateId !== deploy.templateId;
  }

  isDeployQueryExist(deploy: Deploy) {
    console.log(deploy.query, typeof deploy.query);
    return true;
  }

  copyQuery(deploy: Deploy): string {
    return JSON.stringify(deploy.query);
  }

  onCopiedQuery(successful: boolean) {
    if (successful) {
      this.internalNotificationService.showInfo('Cкопійовано!', '')
    }
  }

  getNotAppliedPlayersListByDeploy(deploy: Deploy): string[] {
    const appliedPlayersAsMap: { [key: string]: string } = deploy.appliedPlayers.reduce((pseudoMap, playerId) => {
      pseudoMap[playerId] = playerId;
      return pseudoMap;
    }, {} as { [key: string]: string });

    const notAppliedPlayers: string[] = []
    for (const player of deploy.players ?? []) {
      if (!appliedPlayersAsMap[player]) {
        notAppliedPlayers.push(player);
      }
    }

    return notAppliedPlayers;
  }
}
