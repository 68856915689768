import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DropdownOption, RenovationStatus} from "./renovation";
import {Category, Projection} from "../map-element/map-element";
import {RareLevel, SkinType, SoftCurrency} from "../skin/skin";
import {SetService} from "../set/set.service";
import {ChunkService} from "../chunk/chunk.service";
import {MapElementService} from "../map-element/map-element.service";
import {SkinService} from "../skin/skin.service";

@Injectable({
  providedIn: 'root'
})
export class RenovationService {

  isActiveSetCreationForm: boolean = false;
  isActiveSkinCreationForm: boolean = false
  isActiveMapElementCreationForm: boolean = false
  isActiveChunkCreationForm: boolean = false

  public readonly DropdownProjections: DropdownOption[] = [
    {label: 'left', value: Projection.LEFT.toString()},
    {label: 'right', value: Projection.RIGHT.toString()},
    {label: 'top', value: Projection.TOP.toString()},
    {label: 'down', value: Projection.DOWN.toString()},
    {label: 'free', value: 'null'},
  ];

  public readonly DropdownRareLevel: DropdownOption[] = [
    {label: '0', value: RareLevel.ZERO.toString()},
    {label: '1', value: RareLevel.ONE.toString()},
    {label: '2', value: RareLevel.TWO.toString()},
    {label: '3', value: RareLevel.THREE.toString()},
    {label: '4', value: RareLevel.FOUR.toString()},
    {label: '5', value: RareLevel.FIVE.toString()},
  ];

  public readonly DropdownCurrency: DropdownOption[] = [
    {label: 'coin', value: SoftCurrency.CURRENCY_COIN.toString()},
    {label: 'ruby', value: SoftCurrency.CURRENCY_RUBY.toString()},
  ];

  public readonly DropdownSkinType: DropdownOption[] = [
    {label: 'None', value: SkinType.NONE.toString()},
    {label: 'Базовий / Base', value: SkinType.BASE.toString()},
    {label: 'Звичайний / Common', value: SkinType.COMMON.toString()},
    {label: 'Евент чи TLE', value: SkinType.EVENT_OR_TLE.toString()},
    {label: 'Нагорода / Award', value: SkinType.AWARDS.toString()},
    {label: 'Евент нагорода', value: SkinType.EVENT_AWARD.toString()},
  ];

  public readonly DropdownMapElementCategory: DropdownOption[] = Object.keys(Category)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      label: key.replace(/_/g, ' '),
      value: Category[key].toString()
  }));

  getMapElementCategoryKeyName(category: Category | null): string | null {
    return category ? Category[category.valueOf()].toString() : null
  }

  constructor(
    private http: HttpClient,
    private setService: SetService,
    private chunkService: ChunkService,
    private mapElementService: MapElementService,
    private skinService: SkinService,
  ) {}

  getColorForStatus(status: RenovationStatus) {
    switch (status) {
      case RenovationStatus.PENDING:
        return 'yellow'
      case RenovationStatus.SAVED:
        return 'green'
      case RenovationStatus.CONFIRMED:
        return 'blue'
    }
  }

  activateSkinForm() {
    this.isActiveSkinCreationForm = !this.isActiveSkinCreationForm
    this.isActiveMapElementCreationForm = false
    this.isActiveChunkCreationForm = false
    this.isActiveSetCreationForm = false
    this.clearEditableEntities()
  }

  activateMapElementForm() {
    this.isActiveMapElementCreationForm = !this.isActiveMapElementCreationForm
    this.isActiveSetCreationForm = false
    this.isActiveSkinCreationForm = false
    this.isActiveChunkCreationForm = false
    this.clearEditableEntities()
  }

  activateChunkForm() {
    this.isActiveChunkCreationForm = !this.isActiveChunkCreationForm
    this.isActiveSetCreationForm = false
    this.isActiveSkinCreationForm = false
    this.isActiveMapElementCreationForm = false
    this.clearEditableEntities()
  }

  activateSetForm() {
    this.isActiveSetCreationForm = !this.isActiveSetCreationForm
    this.isActiveSkinCreationForm = false
    this.isActiveMapElementCreationForm = false
    this.isActiveChunkCreationForm = false
    this.clearEditableEntities()
  }

  clearEditableEntities() {
    this.setService.editableSet = null
    this.chunkService.editableChunk = null
    this.mapElementService.editableMapElement = null
    this.skinService.editableSkin = null
  }
}
